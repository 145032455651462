import React from 'react';
import Slider from "react-slick";
import styled, { css } from 'styled-components';
import { Section } from '../../atoms';
import { Container } from '../../molecules';
import { detect } from 'detect-browser';
import Swiper from 'react-id-swiper';


export default function ProjectsSlider(props) {
    const {
        brands,
        jobs,
        skills
    } = props;

    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        variableWidth: true,
        swipeToSlide: true
    };

    var params = {
        slidesPerView: 'auto'
    };

    const renderItemBrand = (item, type, index) => {
        return (
            <Item className={`brand ${index === 0 && "first"}`} key={`brand-${index}`}>
                {index === 0 && <ItemTitle>{type}</ItemTitle>}
                <ListItem>{item.title}</ListItem>
            </Item>
        );
    }

    const renderItemSkill = (item, type, index) => {
        return (
            <Item className={`skill ${index === 0 && "first"}`} key={`skill-${index}`}>
                {index === 0 && <ItemTitle margin>{type}</ItemTitle>}
                <ListItem>{item.title}</ListItem>
            </Item>
        );
    }

    const renderItemJob = (item, type, index) => {
        return (
            <Item className={`job ${index === 0 && "first"}`} key={`job-${index}`}>
                {index === 0 && <ItemTitle margin>{type}</ItemTitle>}
                <ListItem>
                    <Date>
                        <span>{item.start}</span><span>{item.end}</span>
                    </Date>
                    <Company>{item.company}</Company>
                    <Position>{item.job}</Position>
                </ListItem>
            </Item>
        );
    }


    let browser = { name: 'ie' }
    browser = detect();

    return (
        <Section>
            <Overflow>
                <Container>
                    {
                        browser.name === 'safari' ?
                        <SliderOuter safari>
                            <Swiper {...params}>
                                {brands && brands.items.map((item, key) => renderItemBrand(item, brands.title, key))}
                                {jobs && jobs.items.map((item, key) => renderItemJob(item, jobs.title, key))}
                                {skills && skills.items.map((item, key) => renderItemSkill(item, skills.title, key))}
                            </Swiper>
                        </SliderOuter>
                        :
                        <SliderOuter>
                            <Slider {...settings}>
                                {brands && brands.items.map((item, key) => renderItemBrand(item, brands.title, key))}
                                {jobs && jobs.items.map((item, key) => renderItemJob(item, jobs.title, key))}
                                {skills && skills.items.map((item, key) => renderItemSkill(item, skills.title, key))}
                            </Slider>
                        </SliderOuter>
                    }
                </Container>
            </Overflow>
            <Container>
                <LabelDrag>Drag to move</LabelDrag>
            </Container>
        </Section>
    )
}
const Overflow = styled.div`
    overflow-x: hidden;
`;
const SliderOuter = styled.div`
    display: block;
    width: 100%;
    margin-bottom: 72px;
    cursor: grab;

    :active {
        cursor: grabbing;
    }

    .slick-track {
        display: flex;
        align-items: flex-end;
        .slick-slide {
            > div, * {
                &:focus {
                    outline: none;
                }
            }
        }
    }

    ${({ safari }) => safari && css`
        .swiper-wrapper {
            display: flex;
            align-items: flex-end;
            .swiper-slide {
                display: inline-block;
                &.brand {
                    min-width: 72px;
                    &.first {
                        min-width: 109px;
                    }
                }
                &.job {
                    min-width: 120px;
                    &.first {
                        min-width: 237px;
                    }
                }
                &.skill {
                    min-width: 72px;
                    &.first {
                        min-width: 189px;
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            .swiper-wrapper {
                .swiper-slide {
                    &.brand {
                        min-width: calc(60px + (72 - 60) * ((100vw - 320px) / (767 - 320)));
                    }
                    &.job {
                        min-width: calc(102px + (120 - 102) * ((100vw - 320px) / (767 - 320)));

                        &.first {
                            min-width: calc(212px + (237 - 212) * ((100vw - 320px) / (767 - 320)));
                        }
                    }
                    &.skill {
                        min-width: calc(60px + (72 - 60) * ((100vw - 320px) / (767 - 320)));
                        &.first {
                            min-width: calc(170px + (189 - 170) * ((100vw - 320px) / (767 - 320)));
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 576px) {
            .swiper-wrapper {
                .swiper-slide {
                    &.brand {
                        &.first {
                            min-width: 135px;
                        }
                    }
                }
            }
        }
    `}
`;
const Item = styled.div`
    @media screen and (max-width: 576px) {
        &.brand {
            h4 {
                margin-left: 40px;
            }
        }
    }
`;
const ItemTitle = styled.h4`
    display: inline-block;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(-180deg);
    margin: ${({ margin }) => margin ? `0 25px 0 80px` : `0 25px 0 0`};
    text-transform: uppercase;
    font-family: BrownStd;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1em;
    color: #1B1919;
    margin-right: 25px;

    @media screen and (max-width: 767px) {
        font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (767 - 320)));
        margin-right: calc(15px + (25 - 15) * ((100vw - 320px) / (767 - 320)));;
    }
`;
const ListItem = styled.div`
    display: inline-block;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(-180deg);
    font-family: Noe;
    font-size: 48px;
    line-height: 72px;
    color: #000000;
    font-weight: 400;

    ::after {
        content: '';
        display: table;
        clear: both;
    }

    @media screen and (max-width: 767px) {
        font-size: calc(28px + (48 - 28) * ((100vw - 320px) / (767 - 320)));
        line-height: calc(52px + (72 - 52) * ((100vw - 320px) / (767 - 320)));
    }
`;
const Date = styled.div`
    font-family: Noe;
    font-size: 48px;
    line-height: 64px;
    color: #000000;
    > span {
        position: relative;
        display: inline-block;
        &:last-child {
            padding-top: 50px;
            &::before {
                content: '';
                position: absolute;
                top: 10px;
                left: 50%;
                height: 30px;
                width: 2px;
                background-color: #000000;
            }
        }
    }

    @media screen and (max-width: 767px) {
        font-size: calc(28px + (48 - 28) * ((100vw - 320px) / (767 - 320)));
        line-height: calc(44px + (64 - 44) * ((100vw - 320px) / (767 - 320)));
    }
`;
const Company = styled.div`
    font-family: BrownStd;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1B1919;

    @media screen and (max-width: 767px) {
        font-size: calc(11px + (16 - 11) * ((100vw - 320px) / (767 - 320)));
        line-height: calc(16px + (20 - 16) * ((100vw - 320px) / (767 - 320)));
    }
`;
const Position = styled.div`
    font-family: BrownStd;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-left: 20px;

    @media screen and (max-width: 767px) {
        font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (767 - 320)));
        line-height: calc(12px + (16 - 12) * ((100vw - 320px) / (767 - 320)));
    }
`;
const LabelDrag = styled.div`
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-right: 25px;
    letter-spacing: 2px;
    margin-bottom: 96px;
    display: inline-block;
    font-family: BrownStd;
    text-transform: uppercase;
    color: rgba(27, 25, 25, .3);

    &::before {
        content: '';
        width: 60px;
        height: 1px;
        background-color: rgba(0,0,0,.1);
        position: absolute;
        top: 50%;
        left: 100%;
    }
    &::after {
        content: '';
        width: 60px;
        height: 1px;
        background-color: rgba(0,0,0,.8);
        position: absolute;
        top: 50%;
        left: 100%;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1.000);
        -webkit-animation: move 3s infinite; /* Safari 4+ */
        -moz-animation:    move 3s infinite; /* Fx 5+ */
        -o-animation:      move 3s infinite; /* Opera 12+ */
        animation:         move 3s infinite; /* IE 10+, Fx 29+ */
    }

    @keyframes move {
        0%   {
            width: 0;
            margin-left: 0;
        }
        50% {
            width: 60px;
            margin-left: 0;
        }
        100% {
            width: 0px;
            margin-left: 60px;
        }
    }

    @media screen and (max-width: 576px) {
        margin-left: 40px;
    }
`;